'use client';

import React from 'react';

import classNames from 'classnames';
import Link from 'next/link';
import { useParams, useRouter } from 'next/navigation';

import { ApiPlatform } from '@/api/types';
import { RewardCenterSection } from '@/blocks/components/PlatformLink/RewardCenterSection';
import { usePathname } from '@/i18n/routing';
import { filterObjNotNil } from '@/utils/obj';

type BuyCryptoProps = {
  from?: string;
  to?: string;
  amountFrom?: string | number;
  amountTo?: string | number;
};

function getInitProduct(pathname: string) {
  const params: Record<string, string> = {};

  if (pathname.startsWith('/crypto-futures')) {
    params['init-product'] = 'crypto-futures';
  } else if (pathname.startsWith('/cfd-trading')) {
    params['init-product'] = 'margin';
  } else if (pathname.startsWith('/promotions/trading-contests')) {
    params['init-product'] = 'contests';
  } else if (pathname.startsWith('/copy-trade')) {
    params['init-product'] = 'covesting';
  } else if (pathname.startsWith('/buy-crypto')) {
    params['init-product'] = 'buy-crypto';
  }

  return params;
}

export const usePlatformLink = () => {
  const { lang } = useParams();
  const pathname = usePathname();

  return {
    getSignupLink: (params?: { [key: string]: any }) => {
      return (
        `/id/sign-up?` +
        new URLSearchParams(
          filterObjNotNil({
            _lang: lang as string,
            ...getInitProduct(pathname),
            ...params,
          }),
        ).toString()
      );
    },
    getSigninLink: (params?: { [key: string]: any }) => {
      return (
        `/id/sign-in?` +
        new URLSearchParams(
          filterObjNotNil({
            _lang: lang as string,
            ...getInitProduct(pathname),
            ...params,
          }),
        ).toString()
      );
    },
    getStrategyLink: (strategyId: number | string, params?: { [key: string]: any }) => {
      return (
        `/my/covesting/public-strategy/${strategyId}?` +
        new URLSearchParams(
          filterObjNotNil({
            _lang: lang as string,
            ...getInitProduct(pathname),
            ...params,
          }),
        ).toString()
      );
    },
    getBuyCryptoLink: (props?: BuyCryptoProps, params?: { [key: string]: any }) => {
      let url = `/my/buy-crypto`;

      const urlParams = new URLSearchParams(
        filterObjNotNil({
          _lang: lang as string,
          ...getInitProduct(pathname),
          ...params,
        }),
      );

      function validAmount(val: any): val is number | string {
        return typeof val === 'number' || val?.length;
      }

      if (props?.from && props?.to && (validAmount(props.amountFrom) || validAmount(props.amountTo))) {
        url += `/baksta/my/order`;
        urlParams.set('currencyFrom', props.from);
        urlParams.set('currencyTo', props.to);
        if (validAmount(props.amountFrom)) {
          urlParams.set('amountFrom', props?.amountFrom.toString());
        }
        if (validAmount(props.amountTo)) {
          urlParams.set('amountTo', props?.amountTo.toString());
        }
      }

      return url + '?' + new URLSearchParams(urlParams).toString();
    },
  };
};

export const LinkSignup: React.FC<
  React.HTMLAttributes<HTMLAnchorElement> & { params?: { [key: string]: any } }
> = ({ params, className, ...props }) => {
  const { getSignupLink } = usePlatformLink();

  return (
    <Link href={getSignupLink(params)} className={classNames(className)} {...props}>
      {props.children}
    </Link>
  );
};

export const LinkRewardCenter: React.FC<
  React.HTMLAttributes<HTMLAnchorElement> & {
    params?: { [key: string]: any };
    rewardCenterSection: RewardCenterSection;
  }
> = ({ params, rewardCenterSection, ...props }) => {
  return (
    <LinkSignup
      params={{
        ...params,
        redirect:
          rewardCenterSection === RewardCenterSection.WELCOME
            ? platformRewardsWelcomeBonusURL
            : platformRewardsURL,
      }}
      {...props}
    >
      {props.children}
    </LinkSignup>
  );
};

export const LinkSignin: React.FC<
  React.HTMLAttributes<HTMLAnchorElement> & { params?: { [key: string]: any } }
> = ({ params, className, ...props }) => {
  const { getSigninLink } = usePlatformLink();

  return (
    <Link href={getSigninLink(params)} className={classNames(className)} {...props}>
      {props.children}
    </Link>
  );
};

export const LinkStrategy: React.FC<
  React.HTMLAttributes<HTMLAnchorElement> & { strategyId: string | number }
> = ({ strategyId, className, children, ...other }) => {
  const { getStrategyLink } = usePlatformLink();
  return (
    <Link href={getStrategyLink(strategyId)} className={classNames(className)} {...other}>
      {children}
    </Link>
  );
};

/**
 * {from: string; to: string: amountFrom: string | number}
 * or
 * {from: string; to: string: amountTo: string | number}
 *
 * - not both amountTo and amountFrom
 * - either none or all params should be presented, only from or from/to not allowed, either none or all 3
 */
export function LinkBuyCrypto(
  props: React.HTMLAttributes<HTMLAnchorElement> & { params?: { [key: string]: any } } & BuyCryptoProps,
) {
  const { getBuyCryptoLink } = usePlatformLink();
  const { children, params, className, from, to, amountFrom, amountTo, ...other } = props;
  return (
    <Link
      href={getBuyCryptoLink({ from, to, amountFrom, amountTo }, params)}
      className={classNames(className)}
      {...other}
    >
      {children}
    </Link>
  );
}

export function useSignup() {
  const noi18nRouter = useRouter();
  const { getSignupLink } = usePlatformLink();

  function openSignup(params: Record<string, string>) {
    noi18nRouter.push(getSignupLink(params));
  }

  function openSignupWithAsset(asset: { name?: string; platform?: string; _platform?: string }) {
    const params: Record<string, string> = {};
    if (asset?.name) {
      params['asset'] = asset.name;
    }
    if (asset?.platform === ApiPlatform.FX || asset?._platform === ApiPlatform.FX) {
      params['init-product'] = 'crypto-futures';
    } else {
      params['redirect'] = '/my/trade/global/';
    }
    openSignup(params);
  }

  return {
    openSignup,
    openSignupWithAsset: openSignupWithAsset,
  };
}

// Its better to use LinkRewardCenter src/blocks/components/PlatformLink/index.tsx:124
export const platformRewardsURL = '/my/rewards/tasks';
// Its better to use LinkRewardCenter src/blocks/components/PlatformLink/index.tsx:124
export const platformRewardsWelcomeBonusURL = '/my/rewards/promo';
export const platformContestsURL = '/my/contests/all';

export const platformReferralURL = '/my/referral';
