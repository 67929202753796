import { useMemo } from 'react';

import { INavItem } from '@/blocks/sections/Header/data/types';
import { usePathname } from '@/i18n/routing';

export const isIncludesPage = (arr: any[], pathname: string): any => {
  for (let obj of arr) {
    if (obj.href === pathname) {
      return obj;
    }
    if (obj.subitems) {
      let result = isIncludesPage(obj.subitems, pathname);
      if (result) {
        return result;
      }
    }
  }
  return;
};

export function useSelectedProduct(headerData: INavItem[]) {
  const pathname = usePathname();

  let selectedProduct = useMemo(() => {
    let result;
    for (const topLevelNavItem of headerData) {
      result = topLevelNavItem?.subitems?.find(
        (item) =>
          (item.href === pathname || isIncludesPage(item.subitems || [], pathname)) && item.secondNavRow,
      );
      if (result) {
        break;
      }
    }
    return result;
  }, [headerData, pathname]);

  const selectedProductSection = useMemo(() => {
    return pathname === selectedProduct?.href
      ? null
      : selectedProduct?.subitems?.find(
          (subitem) =>
            subitem.href === pathname || (subitem.subitems && isIncludesPage(subitem.subitems, pathname)),
        );
  }, [pathname, selectedProduct?.href, selectedProduct?.subitems]);

  return {
    selectedProduct,
    selectedProductSection,
  };
}
