import { useCallback, useEffect, useRef, useState } from 'react';

import { useGtmLoadedContext } from '@/providers/analytics/gtmLoadedProvider';
import { getCookie } from '@/utils/cookies';

type ParamsMap = Record<string, string>;

const parseLastUtmSourceCookie = (cookieValue: string) => {
  const ignoredValues = ['undefined', '(not set)'];
  const divider = '|';
  return cookieValue.split(divider).reduce<ParamsMap>((acc, entry) => {
    const [key, value] = entry.split('=');
    if (value && !ignoredValues.includes(String(value))) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

function mapKeyNames(obj: ParamsMap, paramsNamesMap: ParamsMap) {
  return Object.entries(obj).reduce<ParamsMap>((acc, [key, value]) => {
    const newParamName = paramsNamesMap[key];
    if (newParamName) {
      acc[newParamName] = value;
    }
    return acc;
  }, {});
}

export function useLastUtmSourceParams(paramsNamesMap: ParamsMap) {
  const { isUtmCookieSet } = useGtmLoadedContext();
  const [lastUtmSourceParams, setLastUtmSourceParams] = useState<undefined | ParamsMap>();
  const utmParamsRetries = useRef<number>(0);

  const getParams = useCallback(() => {
    const lastUtmSourceCookie = getCookie('last_utm_source');
    if (!lastUtmSourceCookie) {
      return;
    }
    const cookieParams = parseLastUtmSourceCookie(lastUtmSourceCookie);
    return mapKeyNames(cookieParams, paramsNamesMap);
  }, [paramsNamesMap]);

  useEffect(() => {
    if (lastUtmSourceParams) {
      return;
    }

    const interval = setInterval(() => {
      if (utmParamsRetries.current > 15) {
        console.error('[useLastUtmSourceParams] params didnt appear after 15s, finish pooling');
        setLastUtmSourceParams({});
        clearInterval(interval);
        return;
      }
      const params = getParams();
      if (params) {
        console.info('[useLastUtmSourceParams] params initialized');
        setLastUtmSourceParams(params);
        clearInterval(interval);
        return;
      }
      utmParamsRetries.current = utmParamsRetries.current + 1;
    }, 1000);

    return () => clearInterval(interval);
  }, [paramsNamesMap, lastUtmSourceParams, isUtmCookieSet, getParams]);

  return { lastUtmSourceParams };
}
