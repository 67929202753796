'use client';

import React, { PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react';

import { createContextHook } from '@/hooks/createContextHook';

export type GtmLoadedContextType = {
  isUtmCookieSet: boolean;
};

export const GtmLoadedContext = createContext<GtmLoadedContextType | undefined>(undefined);

export function GtmLoadedProvider({ children }: PropsWithChildren) {
  const [isUtmCookieSet, setIsUtmCookieSet] = useState(false);

  useEffect(() => {
    const onCookieSetEvent = (e: any) => {
      setIsUtmCookieSet(true);
    };

    // listener for the event sent by the GTM script that sets the 'last_utm_source' cookie
    window.addEventListener('gtm:cset', onCookieSetEvent);

    return () => window.removeEventListener('gtm:cset', onCookieSetEvent);
  }, []);

  const contextValue = useMemo(
    () => ({
      isUtmCookieSet,
    }),
    [isUtmCookieSet],
  );

  return <GtmLoadedContext.Provider value={contextValue}>{children}</GtmLoadedContext.Provider>;
}

export const useGtmLoadedContext = createContextHook(GtmLoadedContext, 'GtmLoadedProvider');
