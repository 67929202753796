import { useEffect, useState } from 'react';

import { cellxpertDataParams, refParams } from '@/global/searchParamsRegistry';
import { getCookie } from '@/utils/cookies';

export enum ReferralType {
  'cellxpert' = 'cellxpert',
  'prime' = 'prime',
}

type ReferralCookie =
  | {
      type: ReferralType.cellxpert;
      createdAt: number;
      value: {
        affid: string;
        affiliate_type: string;
        cxd: string;
      };
    }
  | {
      type: ReferralType.prime;
      createdAt: number;
      value: { ref: string; isGifted?: boolean };
    };

export function getNewRefData(params: URLSearchParams): ReferralCookie | null {
  const ref = params.get(refParams.signup);

  if (ref) {
    const isGifted = params.get(refParams.is_gifted);
    return {
      type: ReferralType.prime,
      createdAt: Date.now(),
      value: { ref, isGifted: isGifted === 'true' || isGifted === '1' },
    };
  }

  const cellxpert = getCellxpertData(params);

  if (cellxpert) {
    return {
      type: ReferralType.cellxpert,
      createdAt: Date.now(),
      value: cellxpert,
    };
  }

  return null;
}

function getCellxpertData(params: URLSearchParams) {
  const pidStr = params.get(cellxpertDataParams.pid);
  const cxdString = params.get(cellxpertDataParams.cxd);
  const type = params.get(cellxpertDataParams.type)?.toLowerCase() === 'ib' ? 'IB' : 'CPA';

  if (!pidStr || !cxdString) {
    return null;
  }
  return {
    affid: pidStr,
    cxd: cxdString,
    affiliate_type: type,
  };
}

export function useReferralInfo() {
  const [partnersInfo, setPartnersInfo] = useState<undefined | ReferralCookie>();

  useEffect(() => {
    try {
      const cookie = getCookie('prm-ref2');
      if (!cookie) {
        return;
      }
      const refInfo = JSON.parse(cookie);
      setPartnersInfo(refInfo);
    } catch {
      console.log('Error parse ref cookie');
    }
  }, []);

  return { referralInfo: partnersInfo };
}
