'use client';

import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

import { ArrowRight } from '@/blocks/components/Icons';
import { LinkRewardCenter } from '@/blocks/components/PlatformLink';
import { RewardCenterSection } from '@/blocks/components/PlatformLink/RewardCenterSection';
import { useHeaderScrollStates } from '@/blocks/sections/Header/hooks/useHeaderScrollStates';
import { EventBlockNames, ctaEventProps } from '@/providers/analytics/types';
import typography from '@/styles/typography.module.scss';

import IconGift from '@/app/[lang]/index/OldHero/icons/giphy.gif';

import styles from './styles.module.scss';

export function HeaderGiftBanner(props: { className?: classNames.Argument | classNames.ArgumentArray }) {
  const tr = useTranslations();
  const { pageScrolledDown } = useHeaderScrollStates();
  return (
    <LinkRewardCenter
      className={classNames(styles.giftBanner, props.className, pageScrolledDown && styles.pageScrolledDown)}
      // needed to replicate category and action here, because for this block parent category is "site_menu", but for cta it should be "site_cta_buttons"
      data-event-label={'bonus_register_now'}
      data-event-block-name={EventBlockNames.header}
      data-event-category={ctaEventProps.event_category}
      data-event-action={ctaEventProps.event_action}
      params={{ 'init-product': null }}
      rewardCenterSection={RewardCenterSection.WELCOME}
    >
      <Image src={IconGift} alt={'Gift'} width={32} height={32} unoptimized />
      <p className={typography.bodyS}>{tr.rich('header.giftBanner')}</p>
      <ArrowRight />
    </LinkRewardCenter>
  );
}
