'use client';

import React, { ReactNode, createContext, useContext, useState } from 'react';

import dynamic from 'next/dynamic';
import { useSearchParams } from 'next/navigation';

import { utilityParams } from '@/global/searchParamsRegistry';

const DownloadAPKModalContext = createContext<{
  openAPKModal: (...args: any[]) => any;
  loading: boolean;
}>({
  openAPKModal: () => {},
  loading: false,
});

const APKModal = dynamic(() => import('@/blocks/components/APKModal/APKModal'));

export function WithAPKModal(props: { children: ReactNode }) {
  const searchParams = useSearchParams();
  const openImmediately = searchParams.get(utilityParams.downloadApk) !== null;
  const [opened, setOpened] = useState(openImmediately);
  const [loading, setLoading] = useState(openImmediately);

  return (
    <DownloadAPKModalContext.Provider
      value={{
        openAPKModal: () => {
          setLoading(true);
          setOpened(true);
        },
        loading,
      }}
    >
      {props.children}
      {opened ? (
        <APKModal isOpen={opened} onClose={() => setOpened(false)} onLoad={() => setLoading(false)} />
      ) : null}
    </DownloadAPKModalContext.Provider>
  );
}

export function useDownloadAPKModal() {
  return useContext(DownloadAPKModalContext);
}
