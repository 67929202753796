import classNames from 'classnames';
import Image from 'next/image';

import { LinkRewardCenter } from '@/blocks/components/PlatformLink';
import { RewardCenterSection } from '@/blocks/components/PlatformLink/RewardCenterSection';

import iconGift from '@/app/images/icons/gift.png';

import styles from './styles.module.scss';

export function ActionGift() {
  return (
    <LinkRewardCenter
      className={classNames(styles.actionGift)}
      data-event-name={'site_menu_register_click'}
      data-event-subcategory={'register'}
      params={{ 'init-product': null }}
      rewardCenterSection={RewardCenterSection.WELCOME}
    >
      <Image src={iconGift} alt={''} />
    </LinkRewardCenter>
  );
}
